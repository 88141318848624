<template>
    <div id="messagePage">
        <div class="headBox">
            <el-button
                type="primary"
                icon="el-icon-caret-left"
                plain
                size="mini"
                @click="back"
                v-if="showBack"
                class="backBtn"
                >返回</el-button
            >
            <div class="title">{{ title }}</div>
        </div>
        <table-list
            :ButtonWidth="120"
            :tableData="tableData"
            :colrs="colrs"
            :total="total"
            :pagesize="pagesize"
            :pageIndex="pageIndex"
            :selection="$route.query.recovery == 0"
            @currentChange="currentChange"
            @handleSizeChange="handleSizeChange"
        >
            <template #TableItem="scope">
                <span v-if="scope.colr.prop == 'read'">
                    <span :class="scope.row[scope.colr.prop] ? 'readGreen' : 'readRen'">
                        {{ scope.row[scope.colr.prop] ? "已读" : "未读" }}
                    </span>
                </span>
                <span v-else-if="scope.colr.prop == 'amount'">
                    {{ scope.row[scope.colr.prop] > 0 ? scope.row[scope.colr.prop].toFixed(2) : 0 }}
                </span>

                <span v-else-if="scope.colr.prop == 'signingStatusText'">
                    <span v-if="scope.row['paymentHistoryList'].length == 0">
                        <span v-if="scope.row[scope.colr.prop] == '已签约'">签约成功</span>
                        <span
                            v-else-if="
                                scope.row[scope.colr.prop] == '手动解约' || scope.row[scope.colr.prop] == '自动解约'
                            "
                            >解约成功</span
                        >
                        <span v-else>
                            {{ scope.row[scope.colr.prop] }}
                        </span>
                    </span>
                    <span v-else>
                        {{ scope.row['paymentHistoryList'][0].repaymentTypeText }}
                    </span>
                </span>
            </template>
            <template #Button="scope" v-if="!showBack">
                <el-button size="mini" type="text" @click="check(scope.row)">查看</el-button>
            </template>
        </table-list>
    </div>
</template>
<script>
import TableList from "../../components/comm/TableList.vue";
import paymentOrderApi from "../../api/paymentOrderApi";
export default {
    name: "messagePage",
    components: { TableList },

    data() {
        return {
            checkId: null,
            checkForm: {},
            showbigImg: false,
            bigImgSrc: "",
            total: 0,
            pagesize: 20,
            pageIndex: 1,
            tableData: [],
            colrs: [
                { label: "订单号", prop: "id" },
                { label: "客户姓名", prop: "customerName" },
                { label: "扣款执行", prop: "repaymentStatusText" },
                { label: "发送日期", prop: "createTime" },
                { label: "状态", prop: "read" }
            ],
            newsNumber: 0,

            showBack: false,
            pageSize: 20,
            pageNumber: 0,
            total: 0,

            title: "消息列表",
            id: this.$route.query.id
        };
    },

    mounted() {
        if (this.$route.query.id && this.$route.query.id != null) {
            this.showBack = true;
            this.getOrderInfo(this.$route.query.id);
            this.title = this.$route.query.id + " 订单扣款详情";
            document.title = "代扣系统-扣款详情";
        } else {
            document.title = "代扣系统-消息列表";
            this.title = "消息列表";
            this.getStages();
        }
        this.$bus.$on("reload-data", this.getStages);
    },
    methods: {
        getStages() {
            this.colrs = [
                { label: "订单号", prop: "id" },
                { label: "客户姓名", prop: "customerName" },
                // { label: "扣款执行", prop: "repaymentStatusText" , width: 100},
                { label: "发送日期", prop: "createTime" },
                { label: "订单状态", prop: "signingStatusText" },
                { label: "状态", prop: "read" }
            ];
            this.title = "消息列表";
            this.showBack = false;
            this.$router.push({ path: this.$route.path, query: {} });
            paymentOrderApi
                .stages({
                    pageSize: this.pageSize,
                    pageNumber: this.pageNumber
                })
                .then(res => {
                    if (res.status == 200) {
                        if (res.data && res.data.length > 0) {
                            this.tableData = res.data;
                        }
                    }
                });
        },

        getOrderInfo(id) {
            this.colrs = [
                { label: "订单号", prop: "contractOrderId" },
                { label: "客户姓名", prop: "customerName" },
                { label: "扣款金额", prop: "amount" },
                { label: "通知消息", prop: "message" },
                { label: "发送日期", prop: "createTime" },
                { label: "状态", prop: "read" }
            ];
            this.$ajax.get("//safepayment.com.cn:18080/contractOrders/stages/" + id + "/read").then(res => {});
            this.$ajax.get("//safepayment.com.cn:18080/contractOrders/stages/" + id).then(res => {
                if (res.status == 200) {
                    this.tableData = [];
                    res.data.paymentHistoryList.forEach(v => {
                        this.$set(v, "read", res.data.read);
                        this.$set(v, "customerName", res.data.customerName);
                        let repaymentStatus = v.repaymentStatus == 1 ? "成功" : "失败";
                        if (v.repaymentType == 2 || v.repaymentType == 3) {
                            this.$set(v, "message", v.repaymentTypeText + repaymentStatus);
                        } else {
                            if (res.data.signingTypeText != "单笔") {
                                this.$set(
                                    v,
                                    "message",
                                    "第" + v.stages + "/" + v.stagesNumber + v.repaymentTypeText + repaymentStatus
                                );
                            } else {
                                this.$set(v, "message", "第" + v.stages + "/1" + v.repaymentTypeText + repaymentStatus);
                            }
                        }

                        this.tableData.push(v);
                    });
                }
            });
        },
        back() {
            this.$router.back();
        },
        check(row) {
            this.showBack = true;
            this.title = row.id + " 订单扣款详情";
            this.getOrderInfo(row.id);
        },

        currentChange(pageIndex) {
            this.pageIndex = pageIndex - 1;
        },
        handleSizeChange(pageSize) {
            this.pageIndex = 1;
            this.pagesize = pageSize;
        }
    }
};
</script>
<style>
</style>
<style lang="scss" scoped>
#messagePage {
    background: #ffffff;
    padding: 20px;
    border-radius: 4px;
    min-height: 85vh;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    overflow: auto;
    .title {
        font-size: 20px;
        font-weight: 700;
        margin: 0 auto;
    }
    .headBox {
        text-align: center;
        margin-bottom: 40px;
        position: relative;
        h3 {
            font-size: 18px;
            font-weight: 500;
            color: #222222;
            line-height: 1;
        }
        .backBtn {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .right {
        display: flex;
        justify-content: right;
        align-items: center;
    }
    .header_tit {
        font-weight: 700;
        font-size: 16px;
        padding-left: 10px;
    }

    .box-card {
        margin-bottom: 20px;
        width: 100%;
        /deep/.el-card__header {
            background: #f4f4f5;
        }
        .clearfix {
            display: flex;
            justify-content: space-between;
            align-items: center;
            i {
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    .readGreen {
        color: #67c23a;
    }
    .readRen {
        color: #f56c6c;
    }
}
</style>

